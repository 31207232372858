var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseTable',{staticClass:"table-shadow",attrs:{"headers":_vm.headers,"items":_vm.info,"loading":_vm.loading,"paginate":true,"lastPage":_vm.nLastPage},on:{"pageChange":function($event){return _vm.fetchParametros(_vm.filters, $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Parâmetros")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('RegisterBottom',{directives:[{name:"can-access",rawName:"v-can-access",value:(907),expression:"907"}],on:{"click":function($event){return _vm.openModal('Cadastrar')}}}),_c('FilterDepartamentos',{on:{"selectedFilters":function($event){_vm.fetchParametros((_vm.filters = $event))}}})],1)]},proxy:true},{key:"item.id_indicador",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id_indicador)+" - "+_vm._s(item.indicador)+" ")]}},{key:"item.id_band",fn:function(ref){
var item = ref.item;
return [_c('BaseSegmento',{attrs:{"segmento":item.id_band}})]}},{key:"item.prioridade_indic",fn:function(ref){
var item = ref.item;
return [(item.prioridade_indic == '>')?_c('v-icon',[_vm._v("mdi-greater-than")]):_c('v-icon',[_vm._v("mdi-less-than")])]}},{key:"item.departamento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("TitleCase")(item.departamento))+" ")]}},{key:"item.mult_empresa",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.status[item.mult_empresa].color}},[_vm._v(" "+_vm._s(_vm.status[item.mult_empresa].icon)+" ")])]}},{key:"item.ativo",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.statusAtivo[item.ativo].color,"dark":""},on:{"click":function($event){return _vm.statusItem(item)}}},[_vm._v(" "+_vm._s(_vm.statusAtivo[item.ativo].text)+" ")])]}},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianCurrency")(item.valor.replace(",", ".")))+" ")]}},{key:"item.valor2",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianCurrency")(item.valor2))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('IconBottom',{directives:[{name:"can-access",rawName:"v-can-access",value:(909),expression:"909"}],attrs:{"name":'edit'},on:{"click":function($event){return _vm.openModal('Editar', item)}}}),_c('IconBottom',{directives:[{name:"can-access",rawName:"v-can-access",value:(910),expression:"910"}],attrs:{"name":'delete'},on:{"click":function($event){return _vm.deleteParametros(item.id_funcao_param_depto)}}})]}}],null,true)}),_c('ModalCadastro',{attrs:{"dialog":_vm.dialog,"labelBtn":_vm.labelBtn,"items":_vm.items},on:{"updateFunctionList":function($event){return _vm.fetchFuncao($event)},"closeModal":function($event){(_vm.dialog = false), _vm.fetchParametros()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }