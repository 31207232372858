<template>
  <v-menu :close-on-content-click="false" max-width="320" offset-x>
    <template v-slot:activator="{ on }">
      <FilterBottom v-on="on" />
    </template>

    <v-card>
      <v-container>
        <v-switch
          v-model="filters.ativo"
          true-value="S"
          false-value="N"
          :label="`${filters.ativo == 'S' ? 'Ativo' : 'Inativo'}`"
          @change="handleFilter()"
        ></v-switch>

        <BaseFilter
          prepend-inner-icon="mdi-file-table-box-multiple-outline"
          clearable
          single-line
          label="Segmento"
          item-text="descricao"
          item-value="id_band"
          service="sistemaService.tipoBandeiras"
          v-model="filters.id_band"
          :isCompany="true"
          @change="handleFilter()"
          :filters="{ id_band: '2,4' }"
        />

        <BaseFilter
          label="Departamento"
          v-model="filters.id_depto"
          service="comissaoService.departamentos"
          item-text="departamento"
          item-value="id_depto"
          prepend-inner-icon="mdi-toolbox"
          clearable
          single-line
          :multiple="false"
          @change="handleFilter()"
        />

        <BaseFilter
          label="Indicador"
          service="sistemaService.indicadores"
          v-model="filters.id_indicador"
          item-text="indicador"
          item-value="id_indicador"
          prepend-inner-icon="mdi-finance"
          clearable
          chips
          single-line
          :filters="{ ativo: 'S' }"
          :hasCode="true"
          :multiple="false"
          @change="handleFilter()"
        />
        <BaseFilter
          label="Tipo de Classe"
          service="comissaoService.tipoClasses"
          v-model="filters.id_tipo_classe"
          item-text="tipo_classe"
          item-value="id_tipo_classe"
          prepend-inner-icon="mdi-chart-bubble"
          clearable
          chips
          single-line
          :multiple="false"
          @change="handleFilter()"
        />
        <BaseFilter
          label="Tipo Comissão"
          service="comissaoService.tipoComissao"
          v-model="filters.id_tipo_comissao"
          item-text="tipo_comissao"
          item-value="id_tipo_comissao"
          clearable
          chips
          :filters="{ ativo: 'S' }"
          single-line
          prepend-inner-icon="mdi-finance"
          @change="handleFilter()"
        />
      </v-container>
    </v-card>
  </v-menu>
</template>
<script>
import BaseFilter from "@/components/shared/BaseSelectService";
import FilterBottom from "@/components/shared/bottons/FilterBottom";

export default {
  name: "FilterDepartamento",
  components: { BaseFilter, FilterBottom },

  data() {
    return {
      filters: { ativo: "S" }
    };
  },
  methods: {
    handleFilter() {
      this.$emit("selectedFilters", this.filters);
    }
  }
};
</script>
